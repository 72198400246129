import React from "react";

/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const ProfileData = (props) => {
    console.log(props.graphData);

    return (
        <div id="profile-div">
            <p><strong>message: </strong> {props.graphData.msg}</p>
        </div>
    );
};
